import styled from "styled-components";

export const Section = styled.div`
  width: 100vw;
  position: relative;
  &#main-section {
    width: 100vw;
    height: calc(100vh);

    .main-section-bg {
      width: 100%;
      height: calc(100vh);
      object-fit: cover;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1;
      transition: opacity 0.5s ease;
      opacity: 0;
    }

    .main-section-bg.visible {
      opacity: 1;
    }

    .wrap {
      height: calc(100vh);
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(3px);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .inner {
      text-align: center;
    }

    .main-section-logo {
      width: 180px;
      margin: auto;
      display: block;
    }

    .border-line {
      width: 3px;
      border-radius: 3px;
      height: 36px;
      background-color: rgba(255, 255, 255, 0.8);
      margin: 12px auto;
    }

    .main-subtitle {
      font-weight: bold;
      color: var(--white);
      font-size: 32px;
      margin-bottom: 24px;
    }

    .main-title {
      font-size: 64px;
      font-weight: bold;
      color: var(--white);

      br {
        display: none;
      }
    }
    @keyframes floating {
      from {
        transform: translate(0, 0px);
      }
      65% {
        transform: translate(0, 15px);
      }
      to {
        transform: translate(0, -0px);
      }
    }
    .user-navi {
      margin-top: 64px;
      animation-name: floating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      p {
        color: var(--white);
        font-weight: bold;
        margin-bottom: 24px;
      }
      svg {
        width: 32px;
        height: 32px;

        path {
          fill: var(--white);
        }
      }
    }
  }

  @media (max-width: 980px) {
    .main-section-logo {
      width: 100px !important;
    }
    .border-line {
      width: 2px !important;
      height: 20px !important;
    }

    .main-subtitle {
      font-size: 20px !important;
      margin-bottom: 12px !important;
    }

    .main-title {
      font-size: 42px !important;

      br {
        display: block !important;
      }
    }

    .user-navi {
      margin-top: 24px !important;
    }
  }

  &#main-second {
    .section-background {
      width: 100%;
      height: calc(100vh);
      object-fit: cover;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1;
    }

    .wrap {
      height: calc(100vh);
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.8);
      display: flex;
      align-items: center;
      justify-content: left;
    }

    .inner {
      text-align: left;
      z-index: 9;
    }

    .main-page-left-label {
      font-size: 20px;
      color: rgba(255, 255, 255, 0.8);
      letter-spacing: 4px;
      font-weight: bold;
      margin-bottom: 24px;
    }

    .main-page-left-text {
      font-size: 32px;
      color: var(--white);
      font-weight: lighter;
    }

    .main-page-go-button {
      background-color: transparent;
      color: var(--blue);
      border: 1px solid var(--blue);
      font-size: 16px;
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 15px;
      margin-top: 40px;
      display: inline-block;
    }

    .main-page-go-button:hover {
      background-color: var(--blue);
      color: var(--black);
    }

    @media (max-width: 980px) {
      .main-page-left-label {
        font-size: 18px;
        letter-spacing: 3px;
        margin-bottom: 12px;
      }

      .main-page-left-text {
        font-size: 20px;
      }

      .main-page-go-button {
        padding: 8px 16px;
        font-size: 14px;
        border-radius: 5px;
        margin-top: 24px;
      }
    }
  }

  &#main-third {
    background-color: #000c19;

    .wrap {
      height: calc(100vh);
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: left;
    }

    .inner {
      text-align: left;
      z-index: 9;
    }

    @media (max-width: 980px) {
      height: auto;
    }
  }

  &#main-last {
    .section-background {
      width: 100%;
      height: calc(100vh);
      object-fit: cover;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1;
    }

    .wrap {
      height: calc(100vh);
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: left;
    }

    .inner {
      text-align: center;
      z-index: 9;
    }
    .last-section-button {
      background-color: transparent;
      color: var(--blue);
      border: 1px solid var(--blue);
      font-size: 16px;
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 15px;
      margin-top: 40px;
      display: inline-block;
    }
    .last-section-label {
      font-size: 20px;
      color: rgba(255, 255, 255, 0.8);
      letter-spacing: 4px;
      font-weight: bold;
      margin-bottom: 24px;
    }

    .last-section-title {
      font-size: 32px;
      color: var(--white);
      margin-bottom: 12px;
    }
    .last-section-text {
      font-size: 20px;
      color: var(--white);
    }
    .last-section-button:hover {
      background-color: var(--blue);
      color: var(--black);
    }
  }

  @media (max-width: 980px) {
    .last-section-label {
      font-size: 18px !important;
      letter-spacing: 3px !important;
      margin-bottom: 12px !important;
    }

    .last-section-title {
      font-size: 20px !important;
    }

    .last-section-text {
      font-size: 14px !important;
    }

    .last-section-button {
      padding: 8px 16px !important;
      font-size: 14px !important;
      border-radius: 5px !important;
      margin-top: 24px !important;
    }
  }
`;
