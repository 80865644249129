import React from "react";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import * as S from "../style/Company.style";

function KakaoMap(props) {
  return (
    <S.Kakaomap>
      <Map
        center={{ lat: 35.8552527241055, lng: 128.649611283701 }}
        style={{ width: "100%", height: "100%" }}
        level={3}
      >
        <MapMarker
          position={{ lat: 35.8552527241055, lng: 128.649611283701 }}
        />
      </Map>
    </S.Kakaomap>
  );
}

export default KakaoMap;
