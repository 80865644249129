import React, { useState, useEffect } from "react";
import * as G from "../style/GlobalStyle.style";
import * as A from "../style/Pages.style";
import * as S from "../style/Contact.style";
import AOS from "aos";
import "aos/dist/aos.css";
import emailjs from "emailjs-com";

import Map from "../component/KakaoMap.js";

const Contact = () => {
  const [formData, setFormData] = useState({
    mail_name: "",
    mail_type: "",
    mail_phone: "",
    mail_email: "",
    mail_content: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    AOS.init();
  }, []);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.mail_name) errors.mail_name = "이름을 입력하세요.";
    if (!formData.mail_type) errors.mail_type = "문의 유형을 선택하세요.";
    if (!formData.mail_phone) errors.mail_phone = "전화번호를 입력하세요.";
    if (!formData.mail_email) {
      errors.mail_email = "이메일을 입력하세요.";
    } else if (!/\S+@\S+\.\S+/.test(formData.mail_email)) {
      errors.mail_email = "유효한 이메일을 입력하세요.";
    }
    if (!formData.mail_content) errors.mail_content = "문의 내용을 입력하세요.";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    emailjs
      .send(
        "service_5weaqiq",
        "template_pyfezut",
        {
          from_name: formData.mail_name,
          mail_type: formData.mail_type,
          mail_name: formData.mail_name,
          mail_phone: formData.mail_phone,
          mail_email: formData.mail_email,
          mail_content: formData.mail_content,
          reply_to: "발신왼료",
        },
        "IpzW7DGxraw1zkzMY"
      )
      .then(
        (result) => {
          alert("문의가 성공적으로 발송되었습니다.");
          setFormData({
            mail_name: "",
            mail_type: "",
            mail_phone: "",
            mail_email: "",
            mail_content: "",
          });
          setErrors({});
        },
        (error) => {
          alert(`문의 발송에 실패했습니다: ${error.text}`);
        }
      );
  };

  return (
    <G.LayoutArea>
      <A.PageArea>
        <A.PageTopArea>
          <div className="wrap">
            <div className="inner">
              <A.PageLabel data-aos="fade-up" data-aos-duration="2000">
                CONTACT US
              </A.PageLabel>
              <A.PageTitle data-aos="fade-up" data-aos-duration="2500">
                문의하기
              </A.PageTitle>
              <S.Section>
                <S.Section id="company-section-second">
                  <div className="wrap">
                    <div className="inner">
                      <form
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        autoComplete="off"
                        className="contact-form"
                        id="contact-form"
                        onSubmit={handleSubmit}
                      >
                        <div className="form-line">
                          <div className="form-label">
                            이름 <span>*</span>{" "}
                          </div>
                          <input
                            type="text"
                            className="form-input"
                            placeholder="이름을 입력하세요"
                            name="mail_name"
                            value={formData.mail_name}
                            onChange={handleInputChange}
                          />
                          {errors.mail_name && (
                            <span className="error">{errors.mail_name}</span>
                          )}
                        </div>
                        <div className="form-line">
                          <div className="form-label">
                            문의 유형 <span>*</span>{" "}
                          </div>
                          <select
                            name="mail_type"
                            className="form-input"
                            value={formData.mail_type}
                            onChange={handleInputChange}
                          >
                            <option disabled value="">
                              문의 유형을 선택하세요.
                            </option>
                            <option value="EDUTECH 문의하기">
                              EDUTECH 문의하기
                            </option>
                            <option value="입시웹사이트 문의하기">
                              입시 웹사이트 문의하기
                            </option>
                            <option value="개발의뢰 문의하기">
                              개발의뢰 문의하기
                            </option>
                            <option value="기타 문의하기">기타 문의하기</option>
                          </select>
                          {errors.mail_type && (
                            <span className="error">{errors.mail_type}</span>
                          )}
                        </div>
                        <div className="form-line">
                          <div className="form-label">
                            전화번호 <span>*</span>{" "}
                          </div>
                          <input
                            type="text"
                            className="form-input"
                            placeholder="전화번호를 입력하세요."
                            name="mail_phone"
                            value={formData.mail_phone}
                            onChange={handleInputChange}
                          />
                          {errors.mail_phone && (
                            <span className="error">{errors.mail_phone}</span>
                          )}
                        </div>
                        <div className="form-line">
                          <div className="form-label">
                            이메일 <span>*</span>{" "}
                          </div>
                          <input
                            type="text"
                            className="form-input"
                            placeholder="이메일을 입력하세요."
                            name="mail_email"
                            value={formData.mail_email}
                            onChange={handleInputChange}
                          />
                          {errors.mail_email && (
                            <span className="error">{errors.mail_email}</span>
                          )}
                        </div>
                        <div className="form-line">
                          <div className="form-label">
                            문의내용 <span>*</span>{" "}
                          </div>
                          <textarea
                            name="mail_content"
                            className="form-textarea"
                            placeholder="문의 내용을 입력하세요."
                            value={formData.mail_content}
                            onChange={handleInputChange}
                          ></textarea>
                          {errors.mail_content && (
                            <span className="error">{errors.mail_content}</span>
                          )}
                        </div>

                        <button type="submit" className="form-submit-button">
                          발송하기
                        </button>
                      </form>

                      <Map />
                      <div class="company-information">
                        <div class="company-information-line">
                          <div
                            class="comp-info-label"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            Address
                          </div>
                          <div
                            class="comp-info-value"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            대구광역시 수성구 달구벌대로528길 15 수성대학교 본관
                          </div>
                        </div>
                        <div class="company-information-line">
                          <div
                            class="comp-info-label"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            CEO
                          </div>
                          <div
                            class="comp-info-value"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            김창환
                          </div>
                          <div
                            class="comp-info-label"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            Employees
                          </div>
                          <div
                            class="comp-info-value"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            2 people
                          </div>
                        </div>
                        <div class="company-information-line">
                          <div
                            class="comp-info-label"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            E-Mail
                          </div>
                          <div
                            class="comp-info-value"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            imnews0772@daum.net
                          </div>
                          <div
                            class="comp-info-label"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            TEL
                          </div>
                          <div
                            class="comp-info-value"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            010-7244-2306
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </S.Section>
              </S.Section>
            </div>
          </div>
        </A.PageTopArea>
      </A.PageArea>
    </G.LayoutArea>
  );
};

export default Contact;
