import React, { useState, useEffect } from "react";
import * as G from "../style/GlobalStyle.style";
import * as A from "../style/Pages.style";
import * as S from "../style/Media.style";
import AOS from "aos";
import "aos/dist/aos.css";

import Media1 from "../asset/img/Media/media-1.png";
import Media2 from "../asset/img/Media/Media-2.jpg";
import Media3 from "../asset/img/Media/media-3.png";
import Media4 from "../asset/img/Media/media-4.jpg";

const Media = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <G.LayoutArea>
      <A.PageArea>
        <A.PageTopArea>
          <div class="wrap">
            <div class="inner">
              <A.PageLabel data-aos="fade-up" data-aos-duration="2000">
                MEDIA
              </A.PageLabel>
              <A.PageTitle data-aos="fade-up" data-aos-duration="2500">
                미디어
              </A.PageTitle>

              <S.Section>
                <S.MediaItem
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  href="https://www.mediatoday.asia/1231440"
                  target="_blank"
                >
                  <img src={Media4} alt="" class="media-item-img" />
                  <div class="media-textArea">
                    <div class="media-title">
                      교육부, 청소년들의 기발한 창업 아이디어가 한자리에 모여요
                    </div>
                    <div class="media-date">2024-11-04</div>

                    <div class="media-text">
                      ‘창업가 이야기 공연(토크 콘서트)’에서는 본 대회에 참가했던
                      청년 창업가 호랑에듀 유도희 대표, 메타버스 플랫폼 젭(ZEP)
                      김상엽 대표와 익스팬드(EXPAND) 김창환 대표가 청년
                      경영인으로서의 생생한 도전 경험과 청소년들을 위한 조언을
                      들려줄 예정이다.
                    </div>
                  </div>
                </S.MediaItem>
                <S.MediaItem
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  href="https://www.hangyo.com/news/article.html?no=103079"
                  target="_blank"
                >
                  <img src={Media2} alt="" class="media-item-img" />
                  <div class="media-textArea">
                    <div class="media-title">
                      학생들의 기발한 창업 아이디어 한자리에
                    </div>
                    <div class="media-date">2024-11-06</div>

                    <div class="media-text">
                      ‘창업가 이야기 공연(토크 콘서트)’에서는 본 대회에 참가했던
                      청년 창업가 호랑에듀 유도희 대표, 메타버스 플랫폼 젭(ZEP)
                      김상엽 대표와 익스팬드(EXPAND) 김창환 대표가 조언을
                      전달했다.
                    </div>
                  </div>
                </S.MediaItem>
                <S.MediaItem
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  href="https://www.mk.co.kr/news/business/10915670"
                  target="_blank"
                >
                  <img src={Media1} alt="" class="media-item-img" />
                  <div class="media-textArea">
                    <div class="media-title">
                      잘나가는 스타트업, 알고보니 고딩사장…미국 IT성지에서도
                      주목
                    </div>
                    <div class="media-date">2024-01-07</div>
                    <div class="media-text">
                      “어릴 때부터 현대, 삼성 같은 기업의 경영인이 멋있다고
                      생각했고 그들의 창업스토리를 공부하며 ‘나도 할 수
                      있겠다’는 생각이 들었죠. 소프트웨어 특성화 고교를 다니며
                      많은 프로젝트 기획을 했고 ...(김창환 익스팬드 대표)
                    </div>
                  </div>
                </S.MediaItem>
                <S.MediaItem
                  data-aos="fade-up"
                  data-aos-duration="2500"
                  href="https://www.youtube.com/watch?v=diqRT5YqIzo"
                  target="_blank"
                >
                  <img src={Media3} alt="" class="media-item-img" />
                  <div class="media-textArea">
                    <div class="media-title">
                      [청소년리포트4] SW 창업 준비하는 '미래 CEO'/ 안동MBC
                    </div>
                    <div class="media-date">2023-05-08</div>

                    <div class="media-text">
                      "당근마켓이나 번개장터같은 커다란 유니콘 기업으로 성장해서
                      청소년들도 충분히 창업해서 신흥 기업이 될 수 있다는 그런
                      좋은 선례 한 번 남겨보고 싶습니다."
                    </div>
                  </div>
                </S.MediaItem>
              </S.Section>
            </div>
          </div>
        </A.PageTopArea>
      </A.PageArea>
    </G.LayoutArea>
  );
};

export default Media;
