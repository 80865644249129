import styled from "styled-components";
export const Section = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;

  .section-bg {
    width: 100%;
    height: 560px;
    border-radius: 20px;
    border-top-left-radius: 50px;
    object-fit: cover;
    filter: brightness(0.7);
  }

  .company-in-area {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    padding: 0px 60px;
  }

  .company-logo {
    width: 360px;
  }

  .company-first-section-text {
    font-size: 20px;
    color: var(--white);
    padding-left: 40px;
    line-height: 36px;
    margin-top: 24px;
  }

  @media (max-width: 980px) {
    .section-bg {
      height: 100%;
      position: absolute;
    }
    .company-logo {
      width: 180px;
      display: block;
      margin: auto;
    }
    .company-in-area {
      position: relative;
      top: auto !important;
      left: auto !important;
      right: auto !important;
      bottom: auto !important;
      padding: 50px 20px;
      transform: none;
    }
    .company-first-section-text {
      font-size: 14px;
      line-height: 24px;
      padding-left: 0px;
      word-break: keep-all;
    }
  }

  &#company-section-second {
    margin: 60px 0px;

    .company-information-line {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      padding: 20px;
      display: flex;
      align-items: center;
      width: calc(100% - 40px);
      background-color: #0b0b0b;
      gap: 20px;
    }

    .company-information-line:first-child {
      border-top: 1px solid var(--white);
    }

    .comp-info-label {
      color: var(--white);
      font-weight: bold;
    }

    .comp-info-value {
      color: rgba(255, 255, 255, 0.8);
      margin-right: 30px;
    }

    @media (max-width: 980px) {
      .company-information-line {
        width: calc(100% - 16px);
        padding: 8px;
        gap: 8px;
      }

      .comp-info-label {
        font-size: 10px;
      }
      .comp-info-value {
        font-size: 12px;
        margin-right: 8px;
      }
    }
  }

  &#company-section-third {
    .section-bg-2 {
      width: 100%;
      height: 800px;
      border-radius: 20px;
      object-fit: cover;
    }

    .company-third-textArea {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      left: 0px;
      text-align: center;
    }

    .company-third-title {
      font-size: 58px;
      font-weight: bold;
      color: var(--white);
      margin-bottom: 24px;
    }
    .company-third-subtitle {
      font-size: 32px;
      font-weight: bold;
      color: var(--white);
      margin-bottom: 12px;
    }
    .company-third-text {
      font-size: 20px;
      color: var(--white);
    }

    @media (max-width: 980px) {
      .section-bg-2 {
        height: 400px !important;
        border-radius: 10px !important;
      }
      .company-third-textArea {
        text-align: center;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(90vw - 40px) !important;
      }
      .company-third-title {
        font-size: 28px;
        word-break: keep-all;
        margin-bottom: 12px;
      }
      .company-third-subtitle {
        font-size: 16px;
        word-break: keep-all;
        margin-bottom: 12px;
      }
      .company-third-text {
        font-size: 14px;
        word-break: keep-all;
        br {
          display: none;
        }
        color: var(--white);
      }
    }
  }
`;

export const Kakaomap = styled.div`
  width: 100%;
  height: 480px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 24px;

  @media (max-width: 980px) {
    height: 320px;
  }
`;
