import React from "react";
import ReactDOM from "react-dom/client"; // Import ReactDOM
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import * as S from "./style/GlobalStyle.style";

//component
import Header from "./component/Header.js";
import Footer from "./component/Footer.js";

//pages
import Home from "./page/Home.js";
import Company from "./page/Company.js";
import History from "./page/History.js";
import Service from "./page/Service.js";
import Portfolio from "./page/Portfolio.js";
import Media from "./page/Media.js";
import Contact from "./page/Contact.js";

const App = () => {
  return (
    <S.GlobalStyle>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Routes>
          <Route path="/Company" element={<Company />} />
        </Routes>{" "}
        <Routes>
          <Route path="/History" element={<History />} />
        </Routes>
        <Routes>
          <Route path="/Service" element={<Service />} />
        </Routes>
        <Routes>
          <Route path="/Portfolio" element={<Portfolio />} />
        </Routes>
        <Routes>
          <Route path="/Media" element={<Media />} />
        </Routes>
        <Routes>
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </S.GlobalStyle>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
