import styled from "styled-components";
export const Section = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;

  @media (max-width: 980px) {
    gap: 12px;
  }
`;

export const MediaItem = styled.a`
  width: calc(50% - 10px);
  display: flex;
  align-items: start;
  justify-content: space-between;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);

  .media-item-img {
    width: 50%;
    border-radius: 10px;
    height: 300px;
    object-fit: cover;
  }

  .media-textArea {
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
  }

  .media-title {
    font-size: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    color: var(--white);
  }
  .media-date {
    margin-top: 8px;
    color: var(--white);
    font-size: 14px;
    margin-bottom: 16px;
  }
  .media-text {
    font-size: 16px;
    word-break: keep-all;
    color: var(--white);
  }

  @media (max-width: 980px) {
    width: 100%;
    display: block;

    .media-item-img {
      width: 100%;
      height: auto;
    }
    .media-textArea {
      width: 100%;
      padding: 0px;
      margin-top: 12px;
    }
    .media-title {
      font-size: 18px;
    }
  }
`;
