import styled from "styled-components";
export const Section = styled.div`
  width: 100%;
  margin-top: 40px;
  position: relative;

  &#company-section-second {
    margin: 60px 0px;

    .company-information-line {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      padding: 20px;
      display: flex;
      align-items: center;
      width: calc(100% - 40px);
      background-color: #0b0b0b;
      gap: 20px;
    }

    .company-information-line:first-child {
      border-top: 1px solid var(--white);
    }

    .comp-info-label {
      color: var(--white);
      font-weight: bold;
    }

    .comp-info-value {
      color: rgba(255, 255, 255, 0.8);
      margin-right: 30px;
    }

    @media (max-width: 980px) {
      .company-information-line {
        width: calc(100% - 16px);
        padding: 8px;
        gap: 8px;
      }

      .wrap,
      .inner {
        width: 100%;
      }

      .comp-info-label {
        font-size: 10px;
      }
      .comp-info-value {
        font-size: 12px;
        margin-right: 8px;
      }
    }
  }

  .contact-form {
    margin-bottom: 60px;
  }

  .form-line {
    padding: 12px 0px;
  }

  .form-label {
    font-size: 16px;
    color: var(--white);
    margin-bottom: 12px;

    span {
      color: red;
    }
  }

  .form-input {
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    box-sizing: border-box;
    border: 1px solid var(--white);
    color: var(--white);

    &::placeholder {
      color: var(--white);
    }
  }
  select {
    color: var(--black);

    option {
      color: var(--black);
    }
  }
  .form-textarea {
    width: 100%;
    border-radius: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    box-sizing: border-box;
    border: 1px solid var(--white);
    color: var(--white);
    padding: 12px;
    resize: none;
    height: 200px;
    &::placeholder {
      color: var(--white);
    }
  }

  .form-submit-button {
    font-size: 16px;
    border: none;
    outline: none;
    display: block;
    width: 30%;
    height: 48px;
    border-radius: 10px;
    background-color: transparent;
    color: var(--blue);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid var(--blue);
    &:hover {
      background-color: var(--blue);
      color: var(--white);
    }
  }

  .error {
    color: red;
    display: block;
    margin-top: 8px;
  }

  @media (max-width: 980px) {
    .form-submit-button {
      padding: 8px 16px !important;
      font-size: 14px !important;
      border-radius: 5px !important;
      margin-top: 24px !important;
    }

    .form-textarea {
      font-size: 12px;
      border-radius: 5px;
    }
    .form-label {
      font-size: 14px;
    }
    .form-input,
    select {
      font-size: 12px;
      border-radius: 5px;
    }
  }
`;
