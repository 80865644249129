import React, { useState, useEffect } from "react";
import * as G from "../style/GlobalStyle.style";
import * as S from "../style/Home.style";
import AOS from "aos";
import "aos/dist/aos.css";

import BackgroundImage from "../asset/img/main-background.png";
import BackgroundSecond from "../asset/img/main-second.png";
import BackgroundLast from "../asset/img/main-last.jpg";
import Slide from "../component/Slider";
import Logo from "../asset/img/logo.png";
import { ReactComponent as MouseIcon } from "../asset/icon/mouse.svg";

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    AOS.init();

    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1500);
    return () => clearTimeout(timer); // Clean up on unmount
  }, []);

  return (
    <G.LayoutArea>
      <S.Section id="main-section">
        <img
          src={BackgroundImage}
          alt=""
          className={`main-section-bg ${isVisible ? "visible" : ""}`}
        />
        <div className="wrap">
          <div className="inner">
            <img
              src={Logo}
              alt=""
              className="main-section-logo"
              data-aos="fade-up"
              data-aos-duration="1500"
            />
            <div
              className="border-line"
              data-aos="fade-up"
              data-aos-duration="2000"
            ></div>
            <div
              className="main-subtitle"
              data-aos="fade-up"
              data-aos-duration="2500"
            >
              학교의 모든 것을 단 하나로 품다.
            </div>
            <div
              className="main-title"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              학교, 달라진 것은 단 하나 전부입니다.
            </div>

            <div
              className="user-navi"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <p>SCROLL DOWN</p>
              <MouseIcon />
            </div>
          </div>
        </div>
      </S.Section>

      <S.Section id="main-second">
        <img className="section-background" src={BackgroundSecond} alt="" />
        <div className="wrap">
          <div className="inner">
            <div
              className="main-page-left-label"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              PRODUCT
            </div>
            <div className="main-page-left-text">
              <p data-aos="fade-up" data-aos-duration="2000">
                번뜩이는 아이디어와 기획력,
              </p>
              <p data-aos="fade-up" data-aos-duration="2300">
                그를 뒷받침하는 개발 능력을 바탕으로
              </p>
              <p data-aos="fade-up" data-aos-duration="2600">
                <strong>웹, 앱, 프로그램 솔루션을 제공</strong>합니다.
              </p>

              <a
                href="/Product"
                class="main-page-go-button"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                익스팬드 서비스 보러가기
              </a>
            </div>
          </div>
        </div>
      </S.Section>

      <S.Section id="main-third">
        <div className="wrap">
          <div className="inner" data-aos="fade-up" data-aos-duration="2000">
            <Slide />
          </div>
        </div>
      </S.Section>

      <S.Section id="main-last">
        <img className="section-background" src={BackgroundLast} alt="" />

        <div className="wrap">
          <div className="inner">
            <div
              class="last-section-label"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              CONTACT US
            </div>
            <div
              class="last-section-title"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <strong>대한민국의 공교육 발전</strong> <br /> 익스팬드가 함께
              합니다.
            </div>
            <div
              class="last-section-text"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              문의사항을 보내주시면 빠른 시일 내에 답변드리겠습니다.
            </div>
            <div
              class="last-section-button"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              익스팬드 문의하기
            </div>
          </div>
        </div>
      </S.Section>
    </G.LayoutArea>
  );
};

export default Home;
