import React, { useState, useEffect } from "react";
import * as G from "../style/GlobalStyle.style";
import * as A from "../style/Pages.style";
import * as S from "../style/Company.style";
import AOS from "aos";
import "aos/dist/aos.css";

import bg1 from "../asset/img/Company-bg-first.png";
import bg2 from "../asset/img/Company-bg-second.png";
import Logo from "../asset/img/logo.png";
import Map from "../component/KakaoMap";
const Company = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <G.LayoutArea>
      <A.PageArea>
        <A.PageTopArea>
          <div class="wrap">
            <div class="inner">
              <A.PageLabel data-aos="fade-up" data-aos-duration="2000">
                COMPANY
              </A.PageLabel>
              <A.PageTitle data-aos="fade-up" data-aos-duration="2500">
                기업소개
              </A.PageTitle>
            </div>
          </div>
        </A.PageTopArea>
        <S.Section>
          <div class="wrap">
            <div class="inner">
              <img
                src={bg1}
                alt=""
                class="section-bg"
                data-aos="fade-up"
                data-aos-duration="2000"
              />

              <div class="company-in-area">
                <img
                  src={Logo}
                  alt=""
                  class="company-logo"
                  data-aos="fade-up"
                  data-aos-duration="2500"
                />
                <div
                  class="company-first-section-text"
                  data-aos="fade-up"
                  data-aos-duration="2800"
                >
                  익스팬드의 발자취는 대표자 스스로의 불편함을 해결하기 위해
                  시작되었습니다.
                  <br />
                  <br />
                  어느 기업보다 주변의 문제에 대하여 깊게 공감하며 <br />
                  아이템을 개발하고정확한 솔루션을 제공합니다. <br />
                  기술을 가진 인재들이 기술을 통하여 불편함이 없는 세상을 만들기
                  위하여 <br />
                  선구자적인 노력을 기울여 왔으며 현재까지 축적된 기술과 경험,{" "}
                  <br />
                  그리고 업계에 쌓인 신뢰감을 바탕으로 변화하는 세상의
                  친인류적인 <br />
                  기업의 주역이 되고자 끊임없는 노력을 하고 있습니다.
                </div>
              </div>
            </div>
          </div>
        </S.Section>

        <S.Section id="company-section-second">
          <div class="wrap">
            <div class="inner">
              <Map />

              <div class="company-information">
                <div class="company-information-line">
                  <div
                    class="comp-info-label"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    Address
                  </div>
                  <div
                    class="comp-info-value"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    대구광역시 수성구 달구벌대로528길 15 수성대학교 본관
                  </div>
                </div>
                <div class="company-information-line">
                  <div
                    class="comp-info-label"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    CEO
                  </div>
                  <div
                    class="comp-info-value"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    김창환
                  </div>
                  <div
                    class="comp-info-label"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    Employees
                  </div>
                  <div
                    class="comp-info-value"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    2 people
                  </div>
                </div>
                <div class="company-information-line">
                  <div
                    class="comp-info-label"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    E-Mail
                  </div>
                  <div
                    class="comp-info-value"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    imnews0772@daum.net
                  </div>
                  <div
                    class="comp-info-label"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    TEL
                  </div>
                  <div
                    class="comp-info-value"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    010-7244-2306
                  </div>
                </div>
              </div>
            </div>
          </div>
        </S.Section>

        <S.Section id="company-section-third">
          <div class="wrap">
            <div class="inner">
              <img
                src={bg2}
                alt=""
                class="section-bg-2"
                data-aos="fade-up"
                data-aos-duration="2000"
              />

              <div class="company-third-textArea">
                <div
                  class="company-third-title"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  COMPANY VISION
                </div>
                <div
                  class="company-third-subtitle"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  마케팅이 필요하지 않은 회사, 누구나 한번쯤 들어봤을 회사
                </div>
                <div
                  class="company-third-text"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  현재 주요거래처인 스타트업/중소/중견기업에 지속적으로 SI
                  솔루션을 제공하고 있으며 <br />
                  우리 주변 문제를 해결하는 플랫폼 서비스 연구 및 개발에 사력을
                  다하고 있습니다.
                  <br />
                  독창적인 아이디어로 저희만의 차별화된 솔루션을 제공하도록
                  노력하겠습니다.
                </div>
              </div>
            </div>
          </div>
        </S.Section>
      </A.PageArea>
    </G.LayoutArea>
  );
};

export default Company;
