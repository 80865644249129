import React, { useState, useEffect } from "react";
import * as G from "../style/GlobalStyle.style";
import * as A from "../style/Pages.style";
import * as S from "../style/Portfolio.style";
import AOS from "aos";
import "aos/dist/aos.css";

import ItemImg1 from "../asset/img/portfolio/DS플래닛.png";
import ItemImg2 from "../asset/img/portfolio/youngan.png";
import ItemImg4 from "../asset/img/portfolio/tj솔루션.png";
import ItemImg5 from "../asset/img/portfolio/xoox.png";
import ItemImg6 from "../asset/img/portfolio/델타랩.png";
import ItemImg7 from "../asset/img/portfolio/동아수풍열.png";
import ItemImg8 from "../asset/img/portfolio/맛점.png";
import ItemImg9 from "../asset/img/portfolio/범.png";
import ItemImg11 from "../asset/img/portfolio/에브리키토.png";
import ItemImg12 from "../asset/img/portfolio/원스탑미디어.png";
import ItemImg13 from "../asset/img/portfolio/meta.png";
import ItemImg14 from "../asset/img/portfolio/펠릭스레코드.png";
import ItemImg15 from "../asset/img/portfolio/aview.png";

import Mou1 from "../asset/img/portfolio/mou/경북교육청.svg";
import Mou2 from "../asset/img/portfolio/mou/경북도청.svg";
import Mou3 from "../asset/img/portfolio/mou/경소마고.png";
import Mou4 from "../asset/img/portfolio/mou/경주디자인고등학교.png";
import Mou5 from "../asset/img/portfolio/mou/경주정보고.webp";
import Mou6 from "../asset/img/portfolio/mou/대구창경.jpg";
import Mou7 from "../asset/img/portfolio/mou/델타랩.webp";
import Mou8 from "../asset/img/portfolio/mou/의성군.jpg";
import Mou9 from "../asset/img/portfolio/mou/중기부.jpg";
import Mou10 from "../asset/img/portfolio/mou/안동시.png";
import Mou11 from "../asset/img/portfolio/mou/softcube.png";
import Mou12 from "../asset/img/portfolio/mou/programmer.gif";

const Portfolio = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <G.LayoutArea>
      <A.PageArea>
        <A.PageTopArea>
          <div class="wrap">
            <div class="inner">
              <A.PageLabel data-aos="fade-up" data-aos-duration="2000">
                PORTFOLIO
              </A.PageLabel>
              <A.PageTitle data-aos="fade-up" data-aos-duration="2500">
                포트폴리오
              </A.PageTitle>

              <S.ImgRow>
                <S.PortfolioItem data-aos="fade-up" data-aos-duration="2000">
                  <img src={ItemImg1} alt="" class="portfolioItem-img" />
                  <div class="portfolioItem-title">D****</div>
                  <div class="portfolioItem-text">기업 웹사이트</div>
                </S.PortfolioItem>

                <S.PortfolioItem data-aos="fade-up" data-aos-duration="2800">
                  <img src={ItemImg4} alt="" class="portfolioItem-img" />
                  <div class="portfolioItem-title">TJ 솔루션</div>
                  <div class="portfolioItem-text">기업 웹사이트</div>
                </S.PortfolioItem>
                <S.PortfolioItem data-aos="fade-up" data-aos-duration="3000">
                  <img src={ItemImg5} alt="" class="portfolioItem-img" />
                  <div class="portfolioItem-title">Xoox Foundation</div>
                  <div class="portfolioItem-text">기업 웹사이트</div>
                </S.PortfolioItem>
                <S.PortfolioItem data-aos="fade-up" data-aos-duration="2000">
                  <img src={ItemImg6} alt="" class="portfolioItem-img" />
                  <div class="portfolioItem-title">DELTA.LABS</div>
                  <div class="portfolioItem-text">기업 웹사이트</div>
                </S.PortfolioItem>
                <S.PortfolioItem data-aos="fade-up" data-aos-duration="2400">
                  <img src={ItemImg7} alt="" class="portfolioItem-img" />
                  <div class="portfolioItem-title">동아수풍열</div>
                  <div class="portfolioItem-text">기업 웹사이트</div>
                </S.PortfolioItem>
                <S.PortfolioItem data-aos="fade-up" data-aos-duration="2000">
                  <img src={ItemImg11} alt="" class="portfolioItem-img" />
                  <div class="portfolioItem-title">E********</div>
                  <div class="portfolioItem-text">기업 웹사이트</div>
                </S.PortfolioItem>
                <S.PortfolioItem data-aos="fade-up" data-aos-duration="2400">
                  <img src={ItemImg2} alt="" class="portfolioItem-img" />
                  <div class="portfolioItem-title">영****</div>
                  <div class="portfolioItem-text">기업 웹사이트</div>
                </S.PortfolioItem>
                <S.PortfolioItem data-aos="fade-up" data-aos-duration="2400">
                  <img src={ItemImg12} alt="" class="portfolioItem-img" />
                  <div class="portfolioItem-title">1stopmedia</div>
                  <div class="portfolioItem-text">기업 웹사이트</div>
                </S.PortfolioItem>
                <S.PortfolioItem data-aos="fade-up" data-aos-duration="3000">
                  <img src={ItemImg14} alt="" class="portfolioItem-img" />
                  <div class="portfolioItem-title">FELIX RECORD</div>
                  <div class="portfolioItem-text">기업 웹사이트</div>
                </S.PortfolioItem>
                <S.PortfolioItem data-aos="fade-up" data-aos-duration="2800">
                  <img src={ItemImg15} alt="" class="portfolioItem-img" />
                  <div class="portfolioItem-title">A-VIEW</div>
                  <div class="portfolioItem-text">기업 웹사이트</div>
                </S.PortfolioItem>

                <S.PortfolioItem data-aos="fade-up" data-aos-duration="3000">
                  <img src={ItemImg9} alt="" class="portfolioItem-img" />
                  <div class="portfolioItem-title">범 : 낭만의시대</div>
                  <div class="portfolioItem-text">게임 사전예약 웹사이트</div>
                </S.PortfolioItem>

                <S.PortfolioItem data-aos="fade-up" data-aos-duration="2800">
                  <img src={ItemImg13} alt="" class="portfolioItem-img" />
                  <div class="portfolioItem-title">메*******</div>
                  <div class="portfolioItem-text">병원 사이트</div>
                </S.PortfolioItem>
              </S.ImgRow>

              <A.PageLabel data-aos="fade-up" data-aos-duration="2000">
                PARTNER
              </A.PageLabel>
              <A.PageTitle data-aos="fade-up" data-aos-duration="2500">
                협력기관 및 MOU 체결기관 <span>(일부 예정포함)</span>
              </A.PageTitle>

              <S.ImgRow>
                <S.PortfolioItemSquare
                  data-aos="fade-up"
                  data-aos-duration="1800"
                >
                  <div class="portfolioItem-imgArea">
                    <img src={Mou1} alt="" class="portfolioItem-img" />
                  </div>
                  <div class="portfolioItem-title">경상북도교육청</div>
                </S.PortfolioItemSquare>
                <S.PortfolioItemSquare
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <div class="portfolioItem-imgArea">
                    <img src={Mou2} alt="" class="portfolioItem-img" />
                  </div>
                  <div class="portfolioItem-title">경상북도청</div>
                </S.PortfolioItemSquare>
                <S.PortfolioItemSquare
                  data-aos="fade-up"
                  data-aos-duration="1800"
                >
                  <div class="portfolioItem-imgArea">
                    <img src={Mou10} alt="" class="portfolioItem-img" />
                  </div>
                  <div class="portfolioItem-title">안동시</div>
                </S.PortfolioItemSquare>
                <S.PortfolioItemSquare
                  data-aos="fade-up"
                  data-aos-duration="1800"
                >
                  <div class="portfolioItem-imgArea">
                    <img src={Mou8} alt="" class="portfolioItem-img" />
                  </div>
                  <div class="portfolioItem-title">의성군</div>
                </S.PortfolioItemSquare>

                <S.PortfolioItemSquare
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <div class="portfolioItem-imgArea">
                    <img src={Mou9} alt="" class="portfolioItem-img" />
                  </div>
                  <div class="portfolioItem-title">중소기업벤처부</div>
                </S.PortfolioItemSquare>
                <S.PortfolioItemSquare
                  data-aos="fade-up"
                  data-aos-duration="2800"
                >
                  <div class="portfolioItem-imgArea">
                    <img src={Mou6} alt="" class="portfolioItem-img" />
                  </div>
                  <div class="portfolioItem-title">대구창조경제혁신센터</div>
                </S.PortfolioItemSquare>
                <S.PortfolioItemSquare
                  data-aos="fade-up"
                  data-aos-duration="2200"
                >
                  <div class="portfolioItem-imgArea">
                    <img src={Mou3} alt="" class="portfolioItem-img" />
                  </div>
                  <div class="portfolioItem-title title-small">
                    경북소프트웨어마이스터고등학교
                  </div>
                </S.PortfolioItemSquare>
                <S.PortfolioItemSquare
                  data-aos="fade-up"
                  data-aos-duration="2400"
                >
                  <div class="portfolioItem-imgArea">
                    <img src={Mou4} alt="" class="portfolioItem-img noradius" />
                  </div>
                  <div class="portfolioItem-title">경주디자인고등학교</div>
                </S.PortfolioItemSquare>
                <S.PortfolioItemSquare
                  data-aos="fade-up"
                  data-aos-duration="2600"
                >
                  <div class="portfolioItem-imgArea">
                    <img src={Mou5} alt="" class="portfolioItem-img" />
                  </div>
                  <div class="portfolioItem-title">경주정보고등학교</div>
                </S.PortfolioItemSquare>

                <S.PortfolioItemSquare
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div class="portfolioItem-imgArea">
                    <img src={Mou7} alt="" class="portfolioItem-img" />
                  </div>
                  <div class="portfolioItem-title">DELTA.LABS</div>
                </S.PortfolioItemSquare>
                <S.PortfolioItemSquare
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div class="portfolioItem-imgArea">
                    <img src={Mou11} alt="" class="portfolioItem-img" />
                  </div>
                  <div class="portfolioItem-title">SOFTCUBE</div>
                </S.PortfolioItemSquare>
                <S.PortfolioItemSquare
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div class="portfolioItem-imgArea">
                    <img src={Mou12} alt="" class="portfolioItem-img" />
                  </div>
                  <div class="portfolioItem-title">사외 개발인력 30여명</div>
                </S.PortfolioItemSquare>
              </S.ImgRow>
            </div>
          </div>
        </A.PageTopArea>
      </A.PageArea>
    </G.LayoutArea>
  );
};

export default Portfolio;
