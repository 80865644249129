import React, { useState } from "react";
import * as G from "../style/GlobalStyle.style";
import Logo from "../asset/img/logo.png";
import { ReactComponent as IconOpen } from "../asset/icon/menu-open.svg";
import { ReactComponent as IconClose } from "../asset/icon/menu-close.svg";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <G.Header>
      <div className="wrap">
        <div className="inner">
          <a href="/" className="header-left">
            <img src={Logo} alt="" className="header-logo-img" />
          </a>

          <div className={`header-link-area ${isOpen ? "opened" : ""}`}>
            <a href="/Company" className="header-link">
              기업소개
            </a>
            <a href="/History" className="header-link">
              연혁
            </a>
            <a href="/Service" className="header-link">
              서비스
            </a>
            <a href="/Portfolio" className="header-link">
              포트폴리오
            </a>
            <a href="/Media" className="header-link">
              미디어
            </a>
            <a href="/Contact" className="header-link mobile-show">
              문의하기
            </a>
          </div>

          <div className="header-right">
            <a href="/Contact" className="header-link">
              문의하기
            </a>

            <div
              className="header-toggle-button mobile-show"
              onClick={handleToggle}
            >
              {isOpen ? <IconClose /> : <IconOpen />}
            </div>
          </div>
        </div>
      </div>
    </G.Header>
  );
};

export default Header;
