import styled from "styled-components";
export const Section = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const ImgRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 90px;

  @media (max-width: 980px) {
    gap: 12px;
  }
`;

export const PortfolioItem = styled.div`
  border-radius: 10px;
  padding: 12px;
  padding-bottom: 24px;
  width: calc((100% - 60px) / 4);
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.15);
  &:hover {
    transform: scale(1.02);
  }
  .portfolioItem-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 12px;
    border-radius: 5px;
  }

  .portfolioItem-title {
    font-size: 20px;
    font-weight: bold;
    color: var(--white);
    margin-bottom: 4px;
    text-align: center;
  }

  .portfolioItem-text {
    text-align: center;
    color: var(--white);
    font-size: 14px;
  }

  @media (max-width: 980px) {
    width: 100%;
    padding-bottom: 12px;
    .portfolioItem-title {
      font-size: 14px;
    }
    .portfolioItem-img {
      height: auto;
    }
    .portfolioItem-text {
      font-size: 12px;
    }
  }
`;

export const PortfolioItemSquare = styled.div`
  border-radius: 10px;
  padding: 20px;
  width: calc((100% - 100px) / 6);
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.15);

  .portfolioItem-imgArea {
    width: 100%;
    height: 0px;
    border-radius: 100%;
    position: relative;
    padding-bottom: 100%;
    margin-bottom: 20px;
    background-color: var(--white);
    img {
      width: 80%;
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 100%;

      transform: translate(-50%, -50%);

      &.noradius {
        border-radius: 0;
      }
    }
  }

  .portfolioItem-title {
    font-size: 20px;
    font-weight: bold;
    color: var(--white);
    margin-bottom: 4px;
    text-align: center;
    word-break: keep-all;
  }

  .title-small {
    font-size: 16px;
  }

  @media (max-width: 980px) {
    width: calc((100% - 12px) / 2);

    .portfolioItem-title {
      font-size: 14px;
    }
    .title-small {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
