import styled from "styled-components";

export const PageArea = styled.div`
  width: 100%;
`;
export const PageTopArea = styled.div`
  padding-top: 120px;
`;
export const PageLabel = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 2px;

  @media (max-width: 980px) {
    font-size: 16px;
    text-align: center;
  }
`;
export const PageTitle = styled.div`
  font-size: 36px;
  color: var(--white);
  font-weight: bold;
  margin-top: 8px;

  span {
    font-size: 14px;
  }

  @media (max-width: 980px) {
    text-align: center;
    font-size: 24px;
    margin-top: 4px;
  }
`;

export const SectionTitle = styled.div`
  font-size: 42px;
  font-weight: bold;
  color: var(--white);
  font-weight: bold;
`;
